import React, { Component } from 'react'
import { Scene } from './Scene.jsx'

import { Container, Nav, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Routes as AppRoutes, TabGroups, Text } from '../Constants.jsx'
import { withRouter } from '../common-react-client/components/Utilities.jsx'
import { Metadata } from '../common-react-client/components/Metadata.jsx'

class Daam extends Component {
  render () {
    const location = this.props.match.location.pathname
    let key = 0
    const title = Text.TITLE

    const appRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.HOME) {
        appRoutes.push(appRoute)
      }
    })

    return (
      <Scene>
        <Container fluid>
          <Metadata title={title} path={location} />
          <br />
          <Nav tabs>
            {
            appRoutes.map(appRoute => (<NavLink
              key={key++} tag={Link} to={appRoute.path} className={classnames({
                active: location === appRoute.path || location === appRoute.homepath
              })}
                                       >
              {appRoute.tabMetaTitle}
            </NavLink>))
          }
          </Nav>

          <div className='section'>
            <div className='row left'>
              <div className='col-lg-8 col-md-12 col-sm-12'>Discipline before Action, Action before Motivation</div>
              <div className='col-lg-4 col-md-8 col-sm-12'>
                <a href='https://daam.io' target='_blank' rel='noopener noreferrer'>DAAM</a>
                <br />
                <a href='https://daam.io/books/discipline' target='_blank' rel='noopener noreferrer'>books/discipline</a>
                <br />
                <a href='https://daam.io/books/action' target='_blank' rel='noopener noreferrer'>books/action</a>
                <br />
                <a href='https://daam.io/books/motivation' target='_blank' rel='noopener noreferrer'>books/motivation</a>
                <br />
                <a href='https://daam.io/daam/discipline' target='_blank' rel='noopener noreferrer'>daam/discipline</a>
                <br />
                <a href='https://daam.io/daam/action' target='_blank' rel='noopener noreferrer'>daam/action</a>
                <br />
                <a href='https://daam.io/daam/motivation' target='_blank' rel='noopener noreferrer'>daam/motivation</a>
                <br />
                <a href='https://daam.io/quotes/discipline' target='_blank' rel='noopener noreferrer'>quotes/discipline</a>
                <br />
                <a href='https://daam.io/quotes/action' target='_blank' rel='noopener noreferrer'>quotes/action</a>
                <br />
                <a href='https://daam.io/quotes/motivation' target='_blank' rel='noopener noreferrer'>quotes/motivation</a>
                <br />
              </div>
            </div>
          </div>
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Daam)
