import React, { Component } from 'react'
import { Metadata } from '../components/Metadata.jsx'
import { withRouter } from '../components/Utilities.jsx'
import { Scene } from '../../scenes/Scene.jsx'

import {
  Button,
  Col,
  Container,
  Media,
  Nav,
  NavLink,
  Row
} from 'reactstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Rating } from '../components/Rating.jsx'
import { Routes as AppRoutes, TabGroups, Text } from '../../Constants.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Videos extends Component {
  handleClick (event) {
    window.open(event.target.value, '_blank')
  }

  render () {
    const location = this.props.match.location.pathname
    let key = 0
    const title = Text.TITLE

    const tabRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.VIDEOS) {
        tabRoutes.push(appRoute)
      }
    })

    const videos = this.props.route.data

    return (
      <Scene>
        <Container fluid>
          <Metadata title={title} path={location} description={videos.intro.desc} keywords={videos.intro.heading} />
          <br />
          <Nav tabs>
            {
            tabRoutes.map(appRoute => (
              <NavLink
                key={key++} tag={Link} to={appRoute.path} className={classnames({
                  active: location === appRoute.path
                })}
              >
                <FontAwesomeIcon icon='angle-double-right' />{appRoute.tabMetaTitle}
              </NavLink>))
          }
          </Nav>
          <h1>{videos.intro.heading}</h1>
          <p>{videos.intro.desc}</p>
          <br /> {
          videos.list.map(video => (
            <Row key={key++}>
              <Col sm='4'>
                <a href={video.home} target='_blank' rel='noopener noreferrer'>
                  <Media src={video.image} height='200' width='246' alt={video.title} className='float-right' />
                </a>
              </Col>
              <Col sm='8' className='text-left'>
                <p>
                  <a href={video.home} target='_blank' rel='noopener noreferrer'>
                    <strong>{video.title}</strong>
                  </a>
                </p>
                <p>Author: {video.author}</p>
                <p>Desc: {video.desc}</p>
                <p>Rating(1-5):
                  <Rating rating={video.rating} />
                </p>
                <p>
                  {video.view.map(site => (<Button key={key++} outline className='btn ExtLink' onClick={this.handleClick} value={site.url}>View in {site.type}</Button>))}
                </p>
                <br />
                <hr />
                <br />
              </Col>
            </Row>))
        }
          <br />
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Videos)
