import Daam from './scenes/Daam.jsx'
import Stoicism from './scenes/Stoicism.jsx'

import About from './common-react-client/scenes/About.jsx'
import Books from './common-react-client/scenes/Books.jsx'
import Contact from './common-react-client/scenes/Contact.jsx'
import Links from './common-react-client/scenes/Links.jsx'
import NotFound from './common-react-client/scenes/NotFound.jsx'
import Podcasts from './common-react-client/scenes/Podcasts.jsx'
import Privacy from './common-react-client/scenes/Privacy.jsx'
import Quotes from './common-react-client/scenes/Quotes.jsx'
import TermsOfUse from './common-react-client/scenes/TermsOfUse.jsx'
import Videos from './common-react-client/scenes/Videos.jsx'

export const Amazon = {
  FIND_IT_URL: 'https://www.amazon.com/ref=as_li_ss_tl?ie=UTF8&linkCode=ll2&tag=beastoic-20&linkId=5f29838b3fd21c4eca0150363af1b00f&language=en_US'
}

export const Site = {
  INSTAGRAM_URL: 'http://instagram.com/beastoic_',
  TWITTER_URL: 'http://twitter.com/beastoic_',
  URL: 'https://beastoic.com'
}

export const Text = {
  ABBREVIATION: 'BEASTOIC',
  DESCRIPTION: 'Get the information and knowledge to help you on the path to a Stoic life. BEASTOIC provides book references, podcast references and stoic quotes to help you on your journey.',
  EMAIL_CONTACT: 'contact@beastoic.com',
  KEYWORDS: 'stoic,quotes,podcast,books,videos',
  TITLE: 'Be a Stoic'
}

export const TabGroups = {
  BOOKS: 'books',
  LINKS: 'links',
  NONE: 'n/a',
  OTHER: 'other',
  PODCASTS: 'podcasts',
  QUOTES: 'quotes',
  STOICISM: 'stoicism',
  VIDEOS: 'videos'
}

export const Paths = {
  ABOUT: '/about/',
  BOOKS_MODERN: '/books/modern/',
  BOOKS_BEAST: '/books/beast/',
  BOOKS_CLASSIC: '/books/classic/',
  CONTACT: '/contact/',
  DAAM: '/daam/',
  HOME: '/',
  LINKS_STOIC: '/links/stoic/',
  NOT_FOUND: '/*',
  PODCASTS_BEAST: '/podcasts/beast/',
  PODCASTS_STOIC: '/podcasts/stoic/',
  PRIVACY: '/privacy/',
  QUOTES_CATO: '/quotes/cato/',
  QUOTES_EPICTETUS: '/quotes/epitetus/',
  QUOTES_JOCKO_WILLINK: '/quotes/jocko/willink/',
  QUOTES_HERACLITUS: '/quotes/heraclitus/',
  QUOTES_MARCUS_AURELIUS: '/quotes/marcus/aurelieus/',
  QUOTES_SENECA: '/quotes/seneca/',
  QUOTES_ZENO_OF_CITIUM: '/quotes/zeno/of/citium/',
  STOICISM_REMINDERS: '/stoicism/reminders/',
  STOICISM_TEACHINGS: '/stoicism/teachings/',
  STOICISM_VIRTUES: '/stoicism/virtues/',
  TERMS_OF_USE: '/terms_of_use/',
  VIDEOS_STOIC: '/videos/stoic/'
}

export const Routes = [
  {
    path: Paths.ABOUT,
    component: About,
    tabGroup: TabGroups.None
  }, {
    path: Paths.CONTACT,
    component: Contact,
    tabGroup: TabGroups.None
  }, {
    path: Paths.DAAM,
    component: Daam,
    tabGroup: TabGroups.OTHER
  }, {
    path: Paths.PRIVACY,
    component: Privacy,
    tabGroup: TabGroups.None
  }, {
    path: Paths.TERMS_OF_USE,
    component: TermsOfUse,
    tabGroup: TabGroups.None
  }, {
    path: Paths.BOOKS_MODERN,
    component: Books,
    data: require('./data/books-modern.json'),
    tabGroup: TabGroups.BOOKS,
    tabMetaTitle: 'Modern Books'
  }, {
    path: Paths.BOOKS_CLASSIC,
    component: Books,
    data: require('./data/books-classic.json'),
    tabGroup: TabGroups.BOOKS,
    tabMetaTitle: 'Classic Books'
  }, {
    path: Paths.BOOKS_BEAST,
    component: Books,
    data: require('./data/books-beast.json'),
    tabGroup: TabGroups.BOOKS,
    tabMetaTitle: 'Beast Books'
  }, {
    path: Paths.LINKS_STOIC,
    component: Links,
    data: require('./data/links-stoic.json'),
    tabGroup: TabGroups.LINKS,
    tabMetaTitle: 'Links'
  }, {
    path: Paths.PODCASTS_STOIC,
    component: Podcasts,
    data: require('./data/podcasts-stoic.json'),
    tabGroup: TabGroups.PODCASTS,
    tabMetaTitle: 'Stoic Podcasts'
  }, {
    path: Paths.PODCASTS_BEAST,
    component: Podcasts,
    data: require('./data/podcasts-beast.json'),
    tabGroup: TabGroups.PODCASTS,
    tabMetaTitle: 'Beast Podcasts'
  }, {
    path: Paths.QUOTES_CATO,
    component: Quotes,
    data: require('./data/quotes-cato.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Cato Quotes'
  }, {
    path: Paths.QUOTES_EPICTETUS,
    component: Quotes,
    data: require('./data/quotes-epictetus.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Epictetus Quotes'
  }, {
    path: Paths.QUOTES_JOCKO_WILLINK,
    component: Quotes,
    data: require('./data/quotes-jocko-willink.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Jocko Willink Quotes'
  }, {
    path: Paths.QUOTES_HERACLITUS,
    component: Quotes,
    data: require('./data/quotes-heraclitus.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Heraclitus Quotes'
  }, {
    path: Paths.QUOTES_MARCUS_AURELIUS,
    component: Quotes,
    data: require('./data/quotes-marcus-aurelius.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Marcus Aurelius Quotes'
  }, {
    path: Paths.QUOTES_SENECA,
    component: Quotes,
    data: require('./data/quotes-seneca.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Seneca Quotes'
  }, {
    path: Paths.QUOTES_ZENO_OF_CITIUM,
    component: Quotes,
    data: require('./data/quotes-zeno-of-citium.json'),
    tabGroup: TabGroups.QUOTES,
    tabMetaTitle: 'Zeno Quotes'
  }, {
    path: Paths.STOICISM_TEACHINGS,
    component: Stoicism,
    data: require('./data/stoic-teachings.json'),
    homepath: Paths.HOME,
    tabGroup: TabGroups.STOICISM,
    tabMetaTitle: 'Stoic Teachings'
  }, {
    path: Paths.STOICISM_VIRTUES,
    component: Stoicism,
    data: require('./data/stoic-virtues.json'),
    tabGroup: TabGroups.STOICISM,
    tabMetaTitle: 'Stoic Virtures'
  }, {
    path: Paths.STOICISM_REMINDERS,
    component: Stoicism,
    data: require('./data/stoic-reminders.json'),
    tabGroup: TabGroups.STOICISM,
    tabMetaTitle: 'Stoic Reminders'
  }, {
    path: Paths.VIDEOS_STOIC,
    component: Videos,
    data: require('./data/videos-stoic.json'),
    tabGroup: TabGroups.VIDEOS,
    tabMetaTitle: 'Stoic Videos'
  }, {
    path: Paths.HOME,
    component: Stoicism,
    data: require('./data/stoic-reminders.json'),
    homepath: Paths.HOME,
    tabGroup: TabGroups.None,
    tabMetaTitle: 'Welcome'
  }, {
    path: Paths.NOT_FOUND,
    component: NotFound,
    tabGroup: TabGroups.None
  }
]

export function dummy () {
  return 'dummy'
}
