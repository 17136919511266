import React, { Component } from 'react'
import { NavMenu as Header } from '../common-react-client/components/NavMenu.jsx'
import { Footer } from '../common-react-client/components/Footer.jsx'
import { Link } from 'react-router-dom'
import { Paths } from '../Constants.jsx'
import { Metadata } from '../common-react-client/components/Metadata.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import MainImage from '../images/marcus-aurelius-main.jpeg'
// import { MainImage } from '../components/MainImage.jsx'
// import { Jumbotron } from 'reactstrap'
// import background from '../images/marcus-aurelius-main.jpeg'
import background from '../images/marcus-aurelius-portrait2.jpeg'

export class Scene extends Component {
  // <Jumbotron dark>
  //    <img src={MainImage} style={{height:'50',width:this.useWindowWidth ()}} alt="*"/>
  // </Jumbotron>

  render () {
    return (
      <div>
        <Metadata title='test' path='https://beastoic.com/x/' />

        <Header />

        <div style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'repeat',
          width: '100%',
          resizeMode: 'stretch'
        }}
        >
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <br />

        <Link to={Paths.STOICISM_TEACHINGS}><FontAwesomeIcon icon='user-friends' />&nbsp;Stoicism</Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.BOOKS_MODERN}><FontAwesomeIcon icon='book-reader' />&nbsp;Books</Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.PODCASTS_STOIC}><FontAwesomeIcon icon='microphone' />&nbsp;Podcasts</Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.QUOTES_MARCUS_AURELIUS}><FontAwesomeIcon icon='quote-left' />&nbsp;Quotes&nbsp;<FontAwesomeIcon icon='quote-right' />
        </Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.VIDEOS_STOIC}><FontAwesomeIcon icon='video' />&nbsp;Videos</Link>
        {' '}&nbsp;&nbsp;&nbsp;{' '}
        <Link to={Paths.LINKS_STOIC}><FontAwesomeIcon icon='link' />&nbsp;Links</Link>

        {this.props.children}

        <Footer />
      </div>
    )
  }
}
