import React, { Component } from 'react'
import { Metadata } from '../components/Metadata.jsx'
import { withRouter } from '../components/Utilities.jsx'
import { Scene } from '../../scenes/Scene.jsx'
import { Col, Container, Media, Nav, NavLink, Row } from 'reactstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Routes as AppRoutes, TabGroups, Text } from '../../Constants.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Links extends Component {
  render () {
    const location = this.props.match.location.pathname
    let key = 0
    const title = Text.TITLE

    const tabRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.LINKS) {
        tabRoutes.push(appRoute)
      }
    })

    const links = this.props.route.data

    return (
      <Scene>
        <Container fluid>
          <Metadata title={title} path={location} description={links.intro.desc} keywords={links.intro.heading} />
          <br />
          <Nav tabs>
            {
            tabRoutes.map(appRoute => (
              <NavLink
                key={key++} tag={Link} to={appRoute.path} className={classnames({
                  active: location === appRoute.path
                })}
              >
                <FontAwesomeIcon icon='angle-double-right' />{appRoute.tabMetaTitle}
              </NavLink>))
          }
          </Nav>
          <h1>{links.intro.heading}</h1>
          <p>{links.intro.desc}</p>
          <br /> {
          links.list.map(link => (
            <div key={key++}>
              <Row key={key++}>
                <Col sm='4'>
                  <a href={link.url} target='_blank' rel='noopener noreferrer'>
                    <Media src={link.image} height='200' width='150' alt={link.url} className='float-right' />
                  </a>
                </Col>
                <Col sm='8' className='text-left'>
                  <p>
                    <a href={link.url} target='_blank' rel='noopener noreferrer'>
                      <strong>{link.title}</strong>
                    </a>
                  </p>
                  <p>{link.desc}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <br />
                  <hr />
                  <br />
                </Col>
              </Row>
            </div>))
        }
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Links)
