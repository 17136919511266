import React, { Component } from 'react'
// import RatingImage from "../images/stoic-rating.ico";
//      ratingImages.push(<img key={i} src='../images/stoic-rating.ico' height='15' width='15' alt='*'/>);

export class Rating extends Component {
  handleClick (event) {
    window.open(event.target.value, '_blank')
  }

  createRating () {
    const ratingImages = []

    let rating = this.props.rating
    if (rating < 1) {
      rating = 1
    } else if (rating > 5) {
      rating = 5
    }

    let i = 0
    for (i = 0; i < rating; i++) {
      ratingImages.push('★')
    }

    return ratingImages
  }

  render () {
    return (
      <span>
        {this.createRating()}
      </span>
    )
  }
}
